.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  justify-self: center;
  text-align: center;

  & svg {
    width: 3.5rem;
    height: 3.5rem;
    height: auto;
  }
}

.main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem 1rem;
  background-position: center;
  background-size: cover;
  gap: 6rem;
}

.background {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  color: transparent;
  inset: 0px;
  object-fit: cover;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.formSection {
  position: relative;
  display: grid;
  overflow: hidden;
  width: min(100%, 420px);
  padding: 2.75rem 1.5rem;
  border-radius: 0.5rem;
  background: var(--color-neutral-900);
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.2);
  color: var(--color-neutral-100);
  transition: all 0.6s ease-in-out;

  & > * {
    animation: fade-in 0.3s ease-in-out;
  }

  @media (min-width: 48em) {
    padding: 2.75rem 2rem;
  }

  &[data-pathname='/'] {
    min-height: 463.75px;
  }

  &[data-pathname='/signup'] {
    min-height: 970px;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.formControls {
  width: 100%;
  padding-top: 1.5rem;

  button {
    width: 100%;
  }
}

.link {
  font-size: 0.75rem;
  text-align: center;
}

.forgotMyPasswordLink {
  padding-block: 1.5rem 2.5rem;
}

/* add fade in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loading {
  position: absolute;
  margin: auto;
  inset: 0;
}
